.fileUploadButton {
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;

    * {
      fill: #7b7c88;
    }
  }

  span {
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.05em;
    color: #000111;
  }

  &:hover {
    color: #0950b4;
    text-decoration: underline;

    svg {
      * {
        fill: #0950b4;
      }
    }

    span {
      color: #0950b4;
    }
  }
}

.uploadArea {
  display: flex;
  position: relative;

  width: 100%;
  height: 40px;
}

.iconButtonArea {
  display: flex;
  align-items: center;
}

.iconArea {
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;

    * {
      fill: #7b7c88;
    }
  }

  &:hover {
    color: #0950b4;
    text-decoration: underline;

    svg {
      * {
        fill: #0950b4;
      }
    }

    span {
      color: #0950b4;
    }
  }
}

.uploadIcon {
  transform: scaleY(-1);
  height: 16px;
}

.uploadTitle {
  height: 100%;
  align-items: center;
  font-size: 14px;
}

.inputArea {
  display: flex;
  margin-left: 44px;

  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: #b5b9c2;

  height: 100%;
  width: 310px;

  align-items: center;
  button {
    padding-top: 4px;
    padding-left: 0px;
  }
}

.fileInput {
  font-size: 16px;
  padding: 0 5px;
  color: gray;

  &::file-selector-button {
    width: 0px; // サイズを固定
    opacity: 0;
    padding: 0px;
    margin: 0px;
  }
}
